<template>
  <main class="page page-documents">
    <BreadcrumbsComponent title="Документы" />
    <div class="page__container">
      <div class="page__content">
        <div class="filters filters--col-4">
          <InputComponent
            v-model.trim="filterForm.title.value"
            :errors="filterForm.title.errors"
            autofocus
            title="Название"
            placeholder="Введите название"
            @input="debounceFilter"
          >
            <template v-slot:action>
              <IconComponent category="vuesax-bold" name="search-normal" />
            </template>
          </InputComponent>
          <DateComponent
            v-model="filterForm.accepted_at.value"
            emptyPlaceholder="Любая"
            mode="date"
            range
            title="Дата принятия"
            @input="filter"
          />
          <DateComponent
            v-model="filterForm.published_at.value"
            emptyPlaceholder="Любая"
            mode="date"
            range
            title="Дата размещения"
            @input="filter"
          />
          <SelectComponent
            v-model="filterForm.type.value"
            :errors="filterForm.type.errors"
            :options="document_types"
            multiple
            label-name="title"
            title="Тип документа"
            placeholder="Все"
            @input="filter"
          />
        </div>
        <span class="page-documents__total">Всего документов: {{ total }}</span>
        <ul class="page-documents__list">
          <li v-for="(act, i) in acts" :key="i">
            <ListDocumentItem :data="act" />
          </li>
        </ul>
        <PaginationComponent
          :first="first"
          :page="page"
          :total="Math.ceil(total / first)"
          :totalItems="parseInt(total || '0')"
          @paginate="paginate"
        />
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/navigation/BreadcrumbsComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import SelectComponent from "components/inputs/select/index.vue";
import IconComponent from "components/IconComponent.vue";
import DateComponent from "components/inputs/DateComponent.vue";
import ListDocumentItem from "components/ListDocumentItem.vue";
import PaginationComponent from "components/PaginationComponent.vue";
import DOCUMENT_LIST_PAGE from "gql/pages/DocumentsListPage.graphql";
import moment from "moment";

export default {
  name: "DocumentsPage",
  async asyncData({ apollo, store, route }) {
    await apollo.defaultClient
      .query({
        query: DOCUMENT_LIST_PAGE,
        variables: { type: parseInt(route.query.type) },
      })
      .then(({ data }) => {
        store.dispatch("documents/save", data);
      })
      .catch(() => {});
  },
  data() {
    return {
      page: 1,
      first: 6,
      filterForm: {
        title: {
          value: null,
          defaultValue: null,
          errors: [],
        },
        accepted_at: {
          value: null,
          defaultValue: null,
          errors: [],
        },
        published_at: {
          value: null,
          defaultValue: null,
          errors: [],
        },
        type: {
          value: [],
          defaultValue: [],
          errors: [],
        },
      },
      debounceInterval: null,
    };
  },
  computed: {
    document_types() {
      return this.$store.state.document_types;
    },
    acts() {
      return this.$store.state.documents.acts_paginate.data;
    },
    total() {
      return this.$store.state.documents.acts_paginate.paginatorInfo.total;
    },
  },
  mounted() {
    if (this.document_types && this.document_types.length) {
      if (this.$route.query.type) {
        this.filterForm.type.value.push(
          this.document_types.find((item) => item.id === parseInt(this.$route.query.type))
        );
      }
    }
  },
  methods: {
    paginate(e) {
      this.page = e;
      this.fetch();
    },
    filter() {
      this.page = 1;
      this.fetch();
    },
    debounceFilter() {
      clearInterval(this.debounceInterval);
      this.debounceInterval = setTimeout(this.filter, 500);
    },
    fetch() {
      let variables = {
        first: this.first,
        page: this.page,
        title: this.filterForm.title.value,
        type: this.filterForm.type.value.map((v) => v.id),
      };
      if (this.filterForm.accepted_at.value) {
        const format = "YYYY-MM-DD HH:mm:ss";
        variables["accepted_at_start_date"] = moment(this.filterForm.accepted_at.value.start).format(format);
        variables["accepted_at_end_date"] = moment(this.filterForm.accepted_at.value.end).format(format);
      }
      if (this.filterForm.published_at.value) {
        const format = "YYYY-MM-DD HH:mm:ss";
        variables["published_at_start_date"] = moment(this.filterForm.published_at.value.start).format(
          format
        );
        variables["published_at_end_date"] = moment(this.filterForm.published_at.value.end).format(format);
      }
      this.$apollo
        .query({
          query: DOCUMENT_LIST_PAGE,
          variables,
        })
        .then(({ data }) => {
          this.$store.dispatch("documents/save", data);
        })
        .catch(() => {});
    },
  },
  metaInfo: {
    title: "Документы",
  },
  components: {
    PaginationComponent,
    ListDocumentItem,
    DateComponent,
    IconComponent,
    SelectComponent,
    InputComponent,
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
.page-documents {
  display grid
  grid-gap 50px
  padding-bottom 50px
  +below(1024px) {
    grid-gap 30px
    padding-bottom 30px
  }

  &__total {
    font-weight: 500;
    font-size: 0.875em;
    line-height: 22px;
    color: var(--dark);
  }

  &__list {
    display grid
    grid-gap 15px

    li {
      display inline-flex
      width 100%
    }
  }

  & .page__content {
    grid-gap 30px
  }
}
</style>
